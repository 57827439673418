import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';


function HeroSlideServ({servname}) {
    const { rpdata } = useContext(GlobalDataContext);

    return(
        <>
            <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
                   { rpdata?.dbServices?.slice(0, 4).map((item, index) => {
                return (
                    <>
                         <SwiperSlide
                         key={index} 
                         className="w-full h-[130vh] lg:h-[100vh] bg-center bg-cover bg-black/20 opacity-100 transition-all" 
                         style={{ backgroundImage: `url("${item.description[0].img}")` }}
                         >
                            <>
                            <h1 className="flex justify-center text-center absolute inset-x-0 bottom-36 lg:inset-y-0 m-auto h-fit textslide"> 
                                {item.name}
                            </h1>
                            </>
                         </SwiperSlide>
                    
                    </>
                    
                )
            })}
      </Swiper>
        </>
    );
}

export default HeroSlideServ;