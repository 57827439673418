import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { BiMapPin, BiCaretRight } from "react-icons/bi";


const Block_9 = () => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
        <div className='flex flex-wrap my-16 py-10 md:py-10'>
            <div className='flex flex-wrap md:border-r-2 text-center w-full py-16 md:w-[50%] px-14 items-center justify-center'
                style={{backgroundImage: `url("${rpdata?.gallery?.[44]}")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}  
            >
                <h4 className='flex text-white'>{rpdata?.dbSlogan?.[2]?.slogan}</h4>
                <div className='flex text-center p-5 rounded-full border-[2px] hover:bg-blue-400'>
                    <BiMapPin className='text-white w-10 h-10'/>
                </div>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
                {/* <p>{rpdata?.dbAbout?.[0]?.text}</p> */}
                <BiMapPin className='text-white w-10 h-10'/>
                <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(0, 9).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><BiCaretRight /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
            <BiMapPin className='text-white w-10 h-10'/>
            <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(9, 18).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><BiCaretRight /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </>
  )
}

export default Block_9;