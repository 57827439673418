import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { BsHouseFill } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";

function ServicesHome_6() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
    {rpdata?.dbServices?.slice(0,6).map((item, index) => {
        return (

            <div className="items-center group w-full max-w-[335px] bg-[#0000000e] border rounded-lg shadow">
              <div class="flex justify-end px-4 pt-4 relative">
                <img
                  className="w-[70px] h-[70px] rounded-full"
                  src={rpdata?.dbPrincipal?.favicon}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center items-center p-5">
                <BsHouseFill className="group-hover:text-white text-sky-500 text-center w-20 h-20 p-6 mb-3 rounded-full shadow-lg  border-sky-500 group-hover:bg-blue-400" />
                <h5 class="mb-1 text-xl font-medium text-gray-900 text-center">
                  {item.name}
                </h5>
                <p className="text-sm text-gray-500 dark:text-gray-500 text-center">
                  {item.description[0].text.substring(0, 160) + "..."}
                </p>
                <div className="mt-4 space-x-3 md:mt-6">
                  <a
                    href={
                        rpdata?.autoGntLandingFromService ?
                          `/${item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`
                          : '/services'
                      }
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    View More
                  </a>
                  <a
                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                  >
                    <FiPhoneCall className="self-center mr-2" />
                    {rpdata?.dbPrincipal?.phones?.[0].phone}
                  </a>
                </div>
              </div>
            </div>

        );
      })}
    </>
    
  );
}

export default ServicesHome_6;
